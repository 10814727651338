@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

img {
    @apply w-full;
}

.section {
    @apply container mx-auto py-24 px-6 min-h-screen;
}

.animate-slide:hover {
    animation-play-state: paused;
}