.player_container {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid rgb(141, 141, 141);
    border-radius: 10px;
    color: rgb(218, 218, 218);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    position: fixed;
    bottom: 0;

    >* {
        padding: 1px 0;
        color: rgb(202, 202, 202);
    }

    .title {
        font-size: inherit;
        text-align: center;

    }

    .navigation {
        width: 100%;


        .navigation_wrapper {
            min-width: 100%;
            background-color: rgba(119, 119, 119, 0.781);
            height: 5px;
            border-radius: 30px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            display: flex;

            .seek_bar {
                width: 0;
                height: 100%;
                background-color: rgb(24, 128, 3);
                border-radius: 30px;
            }

        }
    }


    .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

    }


    .btn_action {
        font-size: 2rem;
        margin: 0 1rem;
        cursor: pointer;
        color: white;

        &:hover {
            color: blue;
        }

    }

    .pp {
        font-size: 2rem;

    }

}